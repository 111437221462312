import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getOrderClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.order.client) {
    menu['/order/client'] = getMenu('/order/client', null, 'Pedidos', 'receipt', null)

    menu['/order/delete/lot/client'] = getMenu('/order/delete/lot/client', '/order/client', 'Exclusão', 'delete', '/order/delete/lot/client')

    menu['/order/import/client'] = getMenu('/order/import/client', '/order/client', 'Cadastro', 'upload_file', null)
    menu['/order/import/csv/client'] = getMenu('/order/import/csv/client', '/order/import/client', 'Csv', 'description', '/order/import/csv/client')
    menu['/order/import/manual/client'] = getMenu(
      '/order/import/manual/client',
      '/order/import/client',
      'Manual',
      'description',
      '/order/import/manual/client',
    )
    menu['/order/import/xml/client'] = getMenu('/order/import/xml/client', '/order/import/client', 'Xml', 'description', '/order/import/xml/client')

    menu['/order/print/client'] = getMenu('/order/print/client', '/order/client', 'Imprimir', 'print', null)

    menu['/order/print/content-declaration/client'] = getMenu(
      '/order/print/content-declaration/client',
      '/order/print/client',
      'Declaração de Conteúdo',
      'receipt_long',
      '/order/print/content-declaration/client',
    )

    menu['/order/print/label/client'] = getMenu(
      '/order/print/label/client',
      '/order/print/client',
      'Etiqueta',
      'receipt_long',
      '/order/print/label/client',
    )

    menu['/order/import/fix/client'] = getMenu('/order/import/fix/client', '/order/client', 'Pacotes Inválidos', 'build', '/order/import/fix/client')

    menu['/order/search/client'] = getMenu('/order/search/client', '/order/client', 'Pesquisa', 'search', '/order/search/client')

    menu['/order/report/client'] = getMenu('/order/report/client', '/order/client', 'Relatórios', 'print', null)
    menu['/order/report/search-lot/client'] = getMenu(
      '/order/report/search-lot/client',
      '/order/report/client',
      'Busca Lote',
      'receipt_long',
      '/order/report/search-lot/client',
    )
    menu['/order/report/orders-with-problems/client'] = getMenu(
      '/order/report/orders-with-problems/client',
      '/order/report/client',
      'Pedidos com Problemas',
      'receipt_long',
      '/order/report/orders-with-problems/client',
    )
    menu['/order/report/performance/client'] = getMenu(
      '/order/report/performance/client',
      '/order/report/client',
      'Performance',
      'receipt_long',
      '/order/report/performance/client',
    )
    menu['/order/report/incident/client'] = getMenu(
      '/order/report/incident/client',
      '/order/report/client',
      'Sinistros',
      'receipt_long',
      '/order/report/incident/client',
    )
  }
}
