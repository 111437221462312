export interface PrintLabelFmOrderList {
  value: PrintLabelFmOrderEnum | null
  label: string
}

export enum PrintLabelFmOrderEnum {
  CreationDate = 'CreationDate',
  RecipientName = 'RecipientName',
  OrderNumber = 'OrderNumber',
  FiscalNoteNumber = 'FiscalNoteNumber',
}

export const PrintLabelFmOrderOptions: Array<PrintLabelFmOrderList> = [
  { value: PrintLabelFmOrderEnum.CreationDate, label: 'Data de Criação' },
  { value: PrintLabelFmOrderEnum.RecipientName, label: 'Nome do Destinatário' },
  { value: PrintLabelFmOrderEnum.OrderNumber, label: 'Núm. Pedido' },
  { value: PrintLabelFmOrderEnum.FiscalNoteNumber, label: 'Núm. Nota Fiscal' },
]
